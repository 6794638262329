<template>
  <div>

    <Lightbox ref="lightbox"/>

    <v-dialog v-model="dialog" fullscreen persistent scrollable>
      <v-card color="#eef2f6" tile>
        <v-card-title>
          Programme d'aujourd'hui
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-subtitle class="font-weight-medium primary--text">
          {{ animator.name }}
        </v-card-subtitle>
        <v-divider/>

        <v-card-text class="pa-lg-10 pa-4">
          <v-row>
            <v-col cols="12" lg="3">

              <v-card class="rounded-lg shadow">
                <v-card-title>
                  <v-icon color="primary" left>mdi-store</v-icon>
                  Points de vente
                  <v-spacer/>
                </v-card-title>
                <v-divider/>
                <v-card-text>

                  <v-skeleton-loader v-if="isLoading" type="article,article"/>

                  <v-list v-else dense nav>
                    <v-list-item-group v-model="salepoint" color="primary"
                                       mandatory>
                      <v-list-item v-for="(item, i) in salepoints"
                                   :key="i"
                                   :value="item"
                      >
                        <v-list-item-avatar v-if="item.salepoint && item.salepoint.photo">
                          <v-img :src="$baseUrl + item.salepoint.photo"
                                 class="zoom-pointer"
                                 @click="$refs.lightbox.open(item.salepoint.photo)"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-avatar v-if="item.salepoint && !item.salepoint.photo">
                          <v-avatar :class="$func.randomColor()"
                                    class="font-weight-medium"
                          >
                            {{ $func.avatarName(item.salepoint.name) }}
                          </v-avatar>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.salepoint ? item.salepoint.name : '' }}
                          </v-list-item-title>
                          <v-list-item-subtitle v-if="item.is_backup"
                                                class="font-weight-medium primary--text">
                            <v-icon color="primary" small>mdi-backup-restore</v-icon>
                            Backup
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="9">

              <v-alert v-if="pointing && pointing.is_validated"
                       border="bottom"
                       class="fs-14"
                       prominent
                       type="success">
                Le travail de ce jour a été validé le :
                <v-icon small>mdi-clock</v-icon>
                <span class="font-weight-medium">
                  {{ $func.dateTimeFormat(pointing.validated_at) }} ,
                </span>
                Vous pouvez modifier vos informations de validation en
                <a class="font-weight-medium white--text text-decoration-underline"
                   @click="$refs.validateWorkDialog.open(pointing)">
                  Cliquant ici
                </a>
              </v-alert>

              <v-chip-group v-model="selectedDate"
                            mandatory
                            next-icon="mdi-arrow-right-bold-circle"
                            prev-icon="mdi-arrow-left-bold-circle"
                            show-arrows
                            @change="getProgram">
                <v-chip v-for="(date,index) in animatorDates"
                        :key="index"
                        :value="date"
                        active-class="primary white--text"
                        class="bg-primary-subtle"
                        filter>
                  {{ $func.dateFormat(date) }}
                </v-chip>

              </v-chip-group>

              <v-card class="rounded-lg shadow mb-3">
                <v-card-title>
                  Pointage & Statistiques de ventes
                </v-card-title>
                <v-card-subtitle class="font-weight-medium primary--text">
                  Date : {{ $func.dateFormat(selectedDate) }}
                </v-card-subtitle>
                <v-divider/>
                <v-card-text>

                  <v-skeleton-loader v-if="isLoading" type="article,article"/>

                  <v-alert v-if="!pointing && !isLoading"
                           border="bottom"
                           class="fs-14 mb-0"
                           color="primary"
                           prominent
                           type="info"
                  >
                    Le travail n'a pas encore commencé dans ce point de vente
                  </v-alert>

                  <div v-if="pointing && !isLoading">

                    <v-row>

                      <v-col class="d-flex flex-column" cols="12" lg="6">
                        <v-card class="rounded-lg flex-grow-1" outlined>
                          <v-card-text>
                            <div class="mb-3">
                              <v-avatar class="mr-2 zoom-pointer" rounded
                                        @click="$refs.lightbox.open(pointing.starting_image)">
                                <v-img :src="$baseUrl + pointing.starting_image" class="grey lighten-4"></v-img>
                              </v-avatar>
                              <v-icon color="primary" dense left>mdi-clock-start</v-icon>
                              Début de travail :
                              <span class="font-weight-medium primary--text">
                                {{ $func.hourFormat(pointing.start_time) }}
                              </span>
                            </div>
                            <div>
                              <v-avatar class="mr-2 zoom-pointer" rounded
                                        @click="$refs.lightbox.open(pointing.ending_image)">
                                <v-img :src="$baseUrl + pointing.ending_image" class="grey lighten-4"></v-img>
                              </v-avatar>
                              <v-icon color="primary" dense left>mdi-clock-end</v-icon>
                              Fin de travail :
                              <span v-if="pointing.end_time" class="font-weight-medium primary--text">
                               {{ $func.hourFormat(pointing.end_time) }}
                              </span>
                              <v-icon v-else color="orange" dense>mdi-close-circle</v-icon>
                            </div>
                          </v-card-text>
                        </v-card>
                        <v-card class="rounded-lg flex-grow-1 mt-5" outlined>
                          <v-card-title>
                            <span class="fs-15">Quantité des cadeaux</span>
                          </v-card-title>
                          <v-card-subtitle>
                            Saisir au début de travail
                          </v-card-subtitle>
                          <v-card-text class="px-0">
                            <div>

                              <v-list v-if="pointing && pointing.pointing_details"
                                      class="py-0 rounded-lg"
                                      dense>

                                <template v-for="(gift,i) in pointing.pointing_details">
                                  <v-list-item :key="i">
                                    <v-list-item-avatar v-if="gift.gift" class="zoom-pointer"
                                                        rounded
                                                        @click="$refs.lightbox.open(gift.gift.image)">
                                      <v-img :src="$baseUrl+gift.gift.image"></v-img>
                                    </v-list-item-avatar>

                                    <v-list-item-content v-if="gift.gift">
                                      <v-list-item-title v-text="gift.gift.name"></v-list-item-title>
                                      <v-list-item-subtitle class="font-weight-medium red--text">
                                        ({{ gift.actual_qty }})
                                      </v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                      <v-chip :class="$func.randomColor()" class="font-weight-medium"
                                              label

                                      >
                                        {{ gift.actual_qty }} / {{ gift.initial_qty }}
                                      </v-chip>
                                    </v-list-item-action>
                                  </v-list-item>
                                  <v-divider v-if="i < pointing.pointing_details.length - 1"
                                             :key="gift.name"/>
                                </template>

                              </v-list>

                              <div v-if="pointing && pointing.pointing_details && !pointing.pointing_details.length"
                                   class="px-4">
                                <v-alert border="bottom" class="fs-14" color="primary" prominent text>
                                  Il n'y a pas de cadeaux dans cette campagne ou ils sont tous du types digitales.
                                </v-alert>
                              </div>

                            </div>
                          </v-card-text>
                        </v-card>
                      </v-col>

                      <v-col class="d-flex flex-column" cols="12" lg="6">
                        <v-card class="rounded-lg flex-grow-1"
                                color="primary"
                                dark outlined>
                          <v-card-title>
                             <span class="fs-16">
                                 Ventes
                             </span>
                            <v-spacer/>
                            <v-avatar class="bg-grad-white">
                              <v-icon>mdi-shopping</v-icon>
                            </v-avatar>
                          </v-card-title>

                          <v-card-subtitle>
                            Ventes de {{ $func.dateFormat(selectedDate) }}
                          </v-card-subtitle>

                          <v-card-text class="white--text">

                            <div class="rounded-lg" style="border:hsla(0,0%,100%,.12) solid 1px">

                              <v-list class="py-0 rounded-lg transparent">

                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>Total Général</v-list-item-title>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-avatar class="font-weight-medium bg-grad-white" rounded size="30">
                                      {{ saleStatistics.all }}
                                    </v-avatar>
                                  </v-list-item-action>
                                </v-list-item>


                                <v-divider/>

                                <v-list-item dense>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <v-icon left>mdi-sign-direction</v-icon>
                                      Contact direct
                                    </v-list-item-title>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-avatar class="font-weight-medium bg-grad-white" rounded size="30">
                                      {{ saleStatistics.direct }}
                                    </v-avatar>
                                  </v-list-item-action>
                                </v-list-item>


                                <v-divider/>

                                <v-list-item dense>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <v-icon left>mdi-sign-direction-minus</v-icon>
                                      Contact indirect
                                    </v-list-item-title>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-avatar class="font-weight-medium bg-grad-white" rounded size="30">
                                      {{ saleStatistics.indirect }}
                                    </v-avatar>
                                  </v-list-item-action>
                                </v-list-item>

                                <v-divider/>

                                <v-list-item dense>
                                  <v-list-item-content>
                                    <v-list-item-title> Type Switch</v-list-item-title>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-avatar class="font-weight-medium bg-grad-white" rounded size="30">
                                      {{ saleStatistics.switch }}
                                    </v-avatar>
                                  </v-list-item-action>
                                </v-list-item>

                                <v-divider/>

                                <v-list-item dense>
                                  <v-list-item-content>
                                    <v-list-item-title> Type Fidélisation</v-list-item-title>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-avatar class="font-weight-medium bg-grad-white" rounded size="30">
                                      {{ saleStatistics.sale }}
                                    </v-avatar>
                                  </v-list-item-action>
                                </v-list-item>

                                <v-divider/>

                                <v-list-item dense>
                                  <v-list-item-content>
                                    <v-list-item-title>Type Refus</v-list-item-title>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <v-avatar class="font-weight-medium bg-grad-white" rounded size="30">
                                      {{ saleStatistics.refusal }}
                                    </v-avatar>
                                  </v-list-item-action>
                                </v-list-item>

                              </v-list>
                            </div>

                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>

                  </div>

                </v-card-text>
              </v-card>

              <div v-if="!isLoading">
                <v-alert v-if="pointing && !pointing.is_validated"
                         border="bottom"
                         class="fs-14"
                         color="primary" text>
                  <v-row align="center">
                    <v-col cols="12" lg="6">
                      Vous ne pouvez pas valider ce travail lorsque les points de début et de fin ne sont pas terminés
                    </v-col>
                    <v-col class="text-end" cols="12" lg="6">
                      <v-btn :disabled="!pointing || !pointing.end_time"
                             color="primary"
                             depressed
                             @click="$refs.validateWorkDialog.open(pointing)">
                        <v-icon left>mdi-check</v-icon>
                        Valider le travail
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </div>

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <Lightbox ref="lightbox"/>
    <ValidateWorkDialog ref="validateWorkDialog" @refresh="getPointing"/>

  </div>
</template>

<script>

import {HTTP} from "@/http-common";
import ValidateWorkDialog from "@/views/supervisor-view/campaigns/sales/components/ValidateWorkDialog.vue";
import * as moment from "moment/moment";

export default {
    components: {ValidateWorkDialog},
    data() {
        return {
            isLoading: false,
            animatorDates: [],
            salepoints: [],
            pointing: {},
            selectedDate: moment().format('YYYY-MM-DD'),

            dialog: false,
            animator: {},
            salepoint: {},
            saleStatistics: {},
        }
    },
    methods: {
        open(animator) {
            this.salepoint = {}
            this.animator = animator
            this.animatorDates = animator.dates && animator.dates.length ? animator.dates.map(el => el.date) : []
            this.dialog = true
            this.getProgram()
        },
        getProgram() {
            this.isLoading = true
            HTTP.get('/supervisor-view/campaigns/' + this.$route.params.id + '/animator-program', {
                params: {
                    animator_id: this.animator.id,
                    date: this.selectedDate
                }
            }).then((res) => {
                this.isLoading = false
               if (res.data && res.data.data){
                  this.salepoints = res.data.data.animator_salepoints
               }else {
                  this.salepoints = []
               }
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        getPointing() {
            this.pointing = {}
            if (this.salepoint && Object.keys(this.salepoint).length) {
                this.isLoading = true
                HTTP.get('/supervisor-view/campaigns/' + this.$route.params.id + '/animator-pointing', {
                    params: {
                        animator_id: this.animator.id,
                        salepoint_id: this.salepoint.salepoint.id,
                        date: this.selectedDate,
                    }
                }).then((res) => {
                    this.pointing = res.data.data.pointing
                    this.saleStatistics = res.data.data.sale_statistics
                    this.isLoading = false
                }).catch(err => {
                    this.isLoading = false
                    console.log(err)
                })
            }
        },
    },
    watch: {
        salepoint() {
            this.getPointing()
        }
    }
}
</script>

<style scoped>

</style>