<template>
  <div>

    <v-dialog v-model="dialog" max-width="500" persistent>
      <v-card flat tile>
        <v-card-title>
          Valider le travail
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-6">

          <p class="font-weight-medium">
            Entrez la quantité de cadeaux retournés <span class="red--text">(*)</span>
          </p>

          <v-card v-for="(gift,i) in pointing_details" :key="i" class="rounded-lg mb-2" outlined>
            <v-card-text>
              <v-row align="center">
                <v-col cols="6">
                  <div class="d-flex align-center">
                    <v-avatar size="50" tile>
                      <v-img :src="$baseUrl +gift.gift.image"
                             class="zoom-pointer rounded"
                             @click="$refs.lightbox.open(gift.gift.image)"
                      ></v-img>
                    </v-avatar>
                    &nbsp;
                    {{ gift.gift.name }}
                  </div>
                  <span class="d-block fs-12 font-weight-medium primary--text">
                    Qte Init : {{ gift.initial_qty }}
                  </span>
                  <span class="d-block fs-12 font-weight-medium red--text">
                      Qte requise  : {{ gift.actual_qty }}
                  </span>
                </v-col>
                <v-col cols="6">

                  <v-text-field v-model="gift.returned_qty"
                                v-number
                                :error-messages="gift_errors['err_'+i]" dense
                                filled
                                hide-details="auto"
                                min="0"
                                suffix="Qte"
                                type="number"
                                @input="gift_errors['err_'+i] ? gift_errors['err_'+i] = '' : false"/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-text-field v-model="good_buy_received"
                        v-number
                        :error-messages="errors.good_buy_received"
                        class="mt-6"
                        dense
                        label="Nombre de bons d'achat(optionnel)"
                        outlined
                        prepend-inner-icon="mdi-ticket-outline"
                        type="number"/>

          <v-textarea v-model="validated_remark"
                      :error-messages="errors.validated_remark"
                      dense
                      label="Remarque(optionnel)" outlined rows="3"/>

        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <v-btn :loading="isLoading"
                 color="secondary"
                 depressed
                 @click="validate">
            <v-icon left>mdi-check</v-icon>
            Valider
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <Lightbox ref="lightbox"/>

  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            dialog: false,
            isLoading: false,
            gift_errors: {},
            errors: {},
            pointing_details: [],
            good_buy_received: null,
            validated_remark: null,
        }
    },
    methods: {
        open(pointing) {
            this.pointing_details = pointing.pointing_details.map(el => {
                el['returned_qty'] = el['actual_qty']
                return el
            })
            this.good_buy_received = pointing.good_buy_received
            this.validated_remark = pointing.validated_remark
            this.gift_errors = {}
            this.errors = {}
            this.dialog = true
        },
        validate() {
            /**
             * Gifts valiation
             */
            this.errors = {}
            this.gift_errors = {}
            this.pointing_details.map((item, index) => {
                if (!item.returned_qty || !(item.returned_qty.toString()).length) {
                    this.gift_errors['err_' + index] = "Ce champ est obligatoir."
                }
            })

            if (Object.keys(this.gift_errors).length === 0) {
                this.isLoading = true
                HTTP.post('/supervisor-view/validate-work', {
                    pointing_details: this.pointing_details,
                    pointing_id: this.pointing_details[0].pointing_id,
                    good_buy_received: this.good_buy_received,
                    validated_remark: this.validated_remark,
                }).then(() => {
                    this.isLoading = false
                    this.dialog = false
                    this.$emit('refresh')
                    this.$successMessage = "Le travail de cet animateur a été validé avec succès."
                }).catch(err => {
                    this.isLoading = false
                    this.errors = err.response.data.errors
                    console.log(err)
                })
            } else {
                this.$errorMessage = "Erreur de validation."
            }
        },
    }
}
</script>

<style scoped>
</style>