<template>
  <div>
    <v-container fluid>
      <Overlay :overlay="overlay"/>
      <ConfirmDialog ref="confirmDialog"/>
      <Lightbox ref="lightbox"/>

      <v-row justify="center">
        <v-col cols="12" lg="10">
          <v-card class="shadow rounded-lg">
            <v-card-title>
              Ventes
              <v-spacer/>

              <v-btn class="mr-2"
                     color="primary"
                     exact
                     left
                     text
                     to="/supervisor-view/campaigns">
                <v-icon color="primary" left>mdi-chevron-left</v-icon>
                Retour
              </v-btn>
            </v-card-title>

            <v-divider/>

            <v-card-text>

              <div v-if="isLoading">
                <v-card class="rounded-lg mb-3" outlined>
                  <v-card-text>
                    <v-skeleton-loader type="avatar,article"/>
                  </v-card-text>
                </v-card>
                <v-card class="rounded-lg mb-3" outlined>
                  <v-card-text>
                    <v-skeleton-loader type="article,article,article,actions"/>
                  </v-card-text>
                </v-card>
              </div>


              <div v-else>

                <v-card class="rounded-lg mb-3" outlined>
                  <v-card-text>
                    <v-row align="center">
                      <v-col cols="12" lg="8" order="1" order-lg="0">
                        <div class="pb-3 font-weight-medium grey--text text--darken-3 fs-17">
                          {{ campaign.name }}
                        </div>

                        <div class="mb-3">

                          <div class="d-block text-no-wrap">
                            <v-icon color="primary" small>mdi-calendar</v-icon>
                            <span class="font-weight-medium"> Date de début : </span>
                            {{ $func.dateFormat(campaign.start_date) }}
                          </div>

                          <div class="d-block text-no-wrap mt-2">
                            <v-icon color="primary" small>mdi-calendar</v-icon>
                            <span class="font-weight-medium"> Date de fin : </span>
                            {{ $func.dateFormat(campaign.end_date) }}
                          </div>

                          <div v-if="campaign.remaining_days"
                               class="d-block text-no-wrap mt-2">
                            <v-icon color="primary" small>mdi-calendar-minus</v-icon>
                            <span class="font-weight-medium"> Jours restants : </span>
                            <v-chip :class="'bg-'+campaign.remaining_days.color+'-subtle'"
                                    :text-color="campaign.remaining_days.color"
                                    class="font-weight-medium fs-13"
                                    dark
                                    label
                                    small>
                              {{ campaign.remaining_days.value }}
                            </v-chip>
                          </div>

                          <div class="d-block text-no-wrap mt-2">
                            <v-icon color="primary" small>mdi-tag</v-icon>
                            <span class="font-weight-medium"> Type : </span>
                            {{ $func.campaignType(campaign.type) }}
                          </div>

                        </div>

                        <p class="grey--text mb-0">
                          {{ campaign.description }}
                        </p>

                      </v-col>
                      <v-col class="text-center" cols="12" lg="4" order="0" order-lg="1">
                        <v-avatar rounded size="120">
                          <v-img v-if="campaign.image" :src="$baseUrl + campaign.image"
                                 class="zoom-pointer rounded-lg"
                                 max-width="130"
                                 min-height="130"
                                 @click="$refs.lightbox.open(campaign.image)"/>

                          <v-img v-else
                                 :src="require('@/assets/no-image.png')"
                                 class="rounded-lg"
                                 max-width="130"
                                 min-height="130"></v-img>
                        </v-avatar>
                      </v-col>
                    </v-row>


                    <v-row>
                      <v-col cols="6" lg="3">

                        <v-list-item two-line class="pa-0">
                          <v-list-item-avatar size="35" class="bg-primary-subtle rounded-lg" tile>
                            <v-icon color="primary" small>mdi-shopping</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{ campaign.total }}</v-list-item-title>
                            <v-list-item-subtitle>Total</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                      </v-col>
                      <v-col cols="6" lg="3">
                        <v-list-item two-line class="pa-0">
                          <v-list-item-avatar size="35" class="bg-orange-subtle rounded-lg" tile>
                            <v-icon color="orange" small>mdi-circle</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{ campaign.switch }}</v-list-item-title>
                            <v-list-item-subtitle>Type Switch</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="6" lg="3">
                        <v-list-item two-line class="pa-0">
                          <v-list-item-avatar size="35" class="bg-blue-subtle rounded-lg" tile>
                            <v-icon color="blue" small>mdi-circle</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{ campaign.sale }}</v-list-item-title>
                            <v-list-item-subtitle>Type Fidélisation</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-col cols="6" lg="3">
                        <v-list-item two-line class="pa-0">
                          <v-list-item-avatar size="35" class="bg-secondary-subtle rounded-lg" tile>
                            <v-icon color="secondary" small>mdi-circle</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{ campaign.refusal }}</v-list-item-title>
                            <v-list-item-subtitle>Type Refus</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                    </v-row>

                  </v-card-text>
                </v-card>

                <v-alert border="bottom" class="fs-14" color="primary" prominent dark>
                  Cette liste montre uniquement les animateurs concernés par la campagne
                  <span class="font-weight-medium">
                    {{ campaign.name }}
                  </span>
                </v-alert>

                <v-data-table
                        :headers="headers"
                        :items="animators"
                        class="table-border rounded-lg"
                        hide-default-footer
                >

                  <template v-slot:item.name="{ item }">
                    <div class="d-flex align-center py-2">

                      <v-avatar v-if="item.photo" size="40">
                        <v-img :src="$baseUrl + item.photo"
                               class="zoom-pointer"
                               @click="$refs.lightbox.open(item.photo)"></v-img>
                      </v-avatar>

                      <v-avatar v-else
                                :class="$func.randomColor()"
                                class="font-weight-medium"
                                size="40">
                        {{ $func.avatarName(item.name) }}
                      </v-avatar>

                      <span class="ml-2">{{ item.name }}</span>
                    </div>
                  </template>

                  <template v-slot:item.email="{ item }">
                    <a :href="'mailto:'+item.email"
                       :style="{maxWidth: '150px'}"
                       class="d-block text-truncate orange--text text-decoration-none">
                      {{ item.email }}
                    </a>
                  </template>

                  <template v-slot:item.program="{ item }">
                    <v-btn color="primary"
                           depressed
                           small
                           @click="$refs.programDialog.open(item)"
                    >
                      Détail
                      <v-icon right small>mdi-arrow-right</v-icon>
                    </v-btn>
                  </template>

                </v-data-table>
              </div>

            </v-card-text>

          </v-card>
        </v-col>
      </v-row>

      <ProgramDialog ref="programDialog"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import ProgramDialog from "@/views/supervisor-view/campaigns/sales/components/ProgramDialog.vue";

export default {
    components: {ProgramDialog},
    data() {
        return {
            campaign: {},
            isLoading: false,
            overlay: false,
            animators: [],
            dates: [],
        }
    },

    methods: {
        getCampaigns() {
            this.isLoading = true
            HTTP.get('/supervisor-view/campaigns/' + this.$route.params.id + '/assignment-animators').then((res) => {
                this.isLoading = false
                this.campaign = res.data.data.campaign
                this.animators = res.data.data.animators.filter(el => el.is_selected === true)
            }).catch(err => {
                this.isLoading = false
                console.log(err)
                /**
                 * Not found ID
                 */
                if (err.response.status === 404) {
                    this.$router.push('/404')
                }
            })
        },
    },
    created() {
        this.getCampaigns()
    },
    computed: {
        headers() {
            return [
                {text: "Animateur", sortable: false, value: 'name'},
                {text: "N°Téléphone", sortable: false, value: 'phone'},
                {text: "Email", sortable: false, value: 'email'},
                {text: "Programme", sortable: false, value: 'program'},
            ]
        }
    }
}
</script>

<style scoped>

</style>